import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import logo from "../images/mave-icon.svg";

const IndexPage = () => (
  <Layout>
    <article>
      <SEO title="Home" />
      <img src={logo} width="120" alt="Mave logo" />
      <h1>Matthias Vervaet</h1>
      <p>Cloud and woodworking enthusiast</p>
      <address>
        <a href="mailto:contact@mave.be?subject=Hallo">contact@mave.be</a>
      </address>
    </article>
  </Layout>
);

export default IndexPage;
